import * as React from 'react'

const Project = ({projectImage,projectImageAlt,projectTitle,projectRole,projectLaunch,projectSkills,projectDescription,projectLink,projectLinkDate}) => {
  return (
      <div className="project">
        <img src={projectImage} alt={projectImageAlt} />
        <div className="description">
          <h2>{projectTitle}</h2>
          <p><strong>My Role(s):</strong> {projectRole}<br />
          <strong>Launch Timeframe:</strong> {projectLaunch}<br />
          <strong>Skills Highlighted:</strong> {projectSkills}</p>
          <p>{projectDescription}</p>
          <p><strong>Project Links:</strong>
          <ul>
            <li><a href={projectLink}>View Live Project</a> &#40;<em>Link Last Verified: {projectLinkDate}</em>&#41;</li>
            <li><em>PDF Download Coming Soon!</em></li>
          </ul>

          </p>
        </div>
      </div>
  )
}

export default Project