import * as React from 'react'
import Layout from '../components/layout'
import Project from '../components/blocks/project'


const ProjectPage = () => {
  let destiny = require('../images/projects/destiny.jpg')
  let explorer = require('../images/projects/sie.jpg')
  let richtmyre = require('../images/projects/richtmyre.jpg')
  return (
    <Layout pageTitle="Projects">
    <div>
      <h1>Projects I've worked on</h1>
      <p>On this page you'll find a selection of notable projects that I have worked on, listed in order of recency. This list will continue to grow in time! If you're interested in hearing more about these projects or other projects that I've been a part of that may not be listed on this page, don't hesitate to <a href="/contact">contact me</a>.</p>
      <Project 
        projectImage={destiny.default}
        projectImageAlt="A screenshot of the Designing Our Destiny platform homepage."
        projectTitle="'Designing Our Destiny' Platform"
        projectRole="Lead Developer"
        projectLaunch="Fall 2022"
        projectSkills="Drupal 7, Bootstrap, SCSS, VideoJS library"
        projectDescription="This project was built into the the existing Americans for the Arts website as a showcase of the new direction for the organization after a long period of strategic reevaluation. The site features a design that departs almost entirely from the existing Americans for the Arts brand and is meant to function like a typical micro-site, however it is technically only a subsection of pages utilizing a different theme with the ThemeKey module. All of the technical pieces of this project were planned and built-out by myself in the span of roughly two months." 
        projectLink="https://www.americansforthearts.org/designing-our-destiny"
        projectLinkDate="December 30, 2022"
      />
      <Project 
        projectImage={explorer.default}
        projectImageAlt="A screenshot of the multicolored wheel of the Arts + Social Impact Explorer."
        projectTitle="Arts + Social Impact Explorer 2.0"
        projectRole="Project Manager, QA, Development Support"
        projectLaunch="Spring 2022"
        projectSkills="Drupal 9, Bootstrap, Twig, jQuery, WCAG Compliance"
        projectDescription="The original Social Impact Explorer was an interactive case-making tool, taking the form of a spinning, colorful wheel, that showcased the power of the arts in communities. It allowed users to select one of several social topics in which the arts intersect (like the arts and military, as an example) and see data points, examples of projects, publications, and organizations doing work that coincides within that area, as well as download a fact sheet for each topic. The second iteration takes these basic concepts and invites users to customize and grow the tool. It features a rebuilt wheel with an all new back-end that allows the wheel's contents to be more easily updated, along with fully filterable databases of local arts projects and arts publications which users can submit examples to. These databases culminate in the biggest new feature of the site: allowing users to create and customize their own fact sheet, with their own hand-picked data points, projects, and publications to best garner support for their case for the arts in their community. This project spanned 18 months of work, beginning with several rounds of focus groups, which informed the design and requirements planning for the project. Our team at Americans for the Arts partnered with an external web development group for much of the heavy lifting on this project, though I was responsible for keeping things in sync and in progress between our teams and jumping in to squash bugs and refine site features."
        projectLink="https://ww2.americansforthearts.org/"
        projectLinkDate="December 30, 2022"
      />
          <Project 
        projectImage={richtmyre.default}
        projectImageAlt="A screenshot of the homepage of the richtmyre.com website."
        projectTitle="This Website! (richtmyre.com)"
        projectRole="Everything"
        projectLaunch="Winter 2022"
        projectSkills="Gatsby, React, Bootstrap"
        projectDescription="My personal site (the one you're viewing right now!) was a fun exercise in using Gastby and React, having spent much of my professional experience largely working exclusively on Drupal-based projects. While it is currently only a handful of pages, I'd like to continue to build out the types of content I feature here, as well as continue to clean and optimize my code. Everything on this site is of my own imagination and creation." 
        projectLink="https://richtmyre.com/"
        projectLinkDate="December 30, 2022"
      />
      <p><em>This is only a small sample of the many things I've worked on over the years; <strong>more projects will be added soon!</strong></em></p>
    </div>
    </Layout>
  )
}


export default ProjectPage